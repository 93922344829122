<template>
    <template v-for="register in getRegisters" v-if="dsRegisterAssignments.state._isLoaded">
        <template v-if="isMobile">
            <div class="mt-3">
                <div class="px-1 d-flex justify-content-between align-items-center text-muted gap-3 mb-0 font-2 fw-bold">
                    <a :href="`/${register.App_ID?register.App_ID:'scope-items'}?Context=${context.id}&Register_ID=${register.Register_ID}&${(register.MobileFilter || register.Filter)?'&filtermode=' + (register.MobileFilter || register.Filter):''}`"   style="cursor:pointer" >{{ register.Register }} 
                        <span :title="$t('Number of planned items where I am participating')" v-if="!responsible">({{ register.NumOfItems }})</span>
                        <span :title="$t('Number of planned items where I am responsible')" v-if="responsible">({{ register.NumOfItems }})</span>
                    </a>
                    <OActionList :title="[ `${$t('Current Context')}:`, context.name ]" @show="newScopeItem(register.Register_ID)">
                        <template #target="{ target }">
                            <OButton :target="target" variant="link" icon="bi-plus-circle font-4 stroke-1" />
                        </template>
                        <template v-if="dsProcesses.state.isLoading">
                            <OActionItem :text="$t('Loading...')" disabled />
                        </template>
                        <template v-else-if="dsProcesses.data.length === 0">
                            <OActionItem :text="$t('No processes for context')" disabled />
                        </template>
                        <template v-else>
                            <template v-for="process in dsProcesses.data">
                                <OActionItem
                                    :text="process.Name"
                                    :href="`/${process.CreateNewApp_ID?process.CreateNewApp_ID:'scope-workflow-new'}?Context=${context.id}&Process-ID=${process.ID}`"
                                />
                            </template>
                        </template>
                    </OActionList>
                </div>
                
                <div class="mt-1">
                    <MCardGroup>
                        <template v-for="row in getItems(register.Register_ID)">
                            <MCard :header="row.Title" :href="getScopeItemDetailLink(row)">
                                <div class="font-n1 text-primary">{{ getScopeItemDate(row) }}</div>
                            </MCard>
                        </template>
                    </MCardGroup>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="mt-3">
                <div class="d-flex flex-wrap text-muted mb-0 border-bottom fw-bold" style="height: 26px;">
                    <button @click.prevent.stop="() => toggleCollapse(register.Register_ID)" class="btn btn-sm btn-link p-0 mx-1" style="height: 26px;">
                        <i class="bi bi-chevron-right register-chevron" :class="{ 'expanded': !collapsedState(register.Register_ID).isCollapsed}"></i>
                    </button>

                    <a :href="`/${register.App_ID?register.App_ID:'scope-items'}?Context=${context.id}&Register_ID=${register.Register_ID}&${register.Filter?'&filtermode=' + register.Filter:''}`" 
                        style="cursor:pointer" >{{ register.Register }} 
                        <span :title="$t('Number of planned items where I am participating')" v-if="!responsible">({{ register.NumOfItems }})</span>
                        <span :title="$t('Number of planned items where I am responsible')" v-if="responsible">({{ register.NumOfItems }})</span>
                    </a>
                    <button class="btn btn-link btn-sm drag-ignore dropdown-toggle fw-bold ms-auto" data-bs-toggle="dropdown" @click="newScopeItem(register.Register_ID)">{{ $t("New") }}</button>
                    <ul class="dropdown-menu">
                        <li>
                            <div class="dropdown-header mark">
                                <div>{{ $t("Current Context")}}</div>
                                <div>{{ context.name }}</div>
                            </div>
                        </li>
                        <li v-if="dsProcesses.data.length == 0">
                            <a class="dropdown-item disabled" href="#" disabled="true">
                            <span>{{ $t("No processes for context") }}</span></a>
                        </li>
                        <li v-for="vRow in dsProcesses.data">
                            <a class="dropdown-item" :href="`/${vRow.CreateNewApp_ID?vRow.CreateNewApp_ID:'scope-workflow-new'}?Context=${context.id}&Process-ID=${vRow.ID}`" style="cursor:pointer" disabled="true">
                            <span>{{ vRow.Name }}</span></a>
                        </li>
                    </ul>
                </div>
                
                <OCollapse v-bind:show="!collapsedState(register.Register_ID).isCollapsed">
                    <div class="list-unstyled w-100 mb-0">
                        <div v-for="row in getItems(register.Register_ID)">
                            <span class="d-flex text-truncate gap-1 drag-ignore">
                                <a :href="getScopeItemDetailLink(row)" v-scope-hover="row.ID" class="d-flex gap-1" style="cursor:pointer">
                                    <span v-if="row.PlannedStart && row.IsMeeting">{{ utils.formatDate(row.PlannedStart, 'General Date Short Time') }}:</span>
                                    <span v-if="row.PlannedStart && !row.IsMeeting">{{ utils.formatDate(row.PlannedStart, 'Short Date') }}:</span>
                                    <span>{{ row.Title }}</span>
                                    <span
                                        v-if="row.DueDate"
                                        :class="{
                                            'text-danger': parseDate(row.DueDate) <= dateNow,
                                            'text-muted': parseDate(row.DueDate) > dateNow
                                        }"
                                    >
                                        ({{ utils.formatDate(row.DueDate, 'Short Date') }})
                                    </span>
                                </a>
                            </span>
                        </div>
                    </div>
                </OCollapse>
            </div>
        </template>
    </template>
</template>

<script setup lang="ts">
import { defineProps, computed, watch, ref } from 'vue';
import { logger, utils } from 'o365-utils';
import { context, localStorageHelper } from 'o365-modules';
import { getOrCreateDataObject } from 'o365-dataobject';
import vScopeHover from 'scope.libraries.vue.directive.scopeItemHover.ts';
import { isMobile } from 'o365.GlobalState.ts';
import { useAsyncComponent } from "o365-vue-utils";
import { OActionList, OActionItem } from "o365-ui-components";

const MCard = useAsyncComponent("o365-mobile/MCard");
const MCardGroup = useAsyncComponent("o365-mobile/MCardGroup");
const OCollapse = useAsyncComponent('o365-propertygrid/OCollapse', {
    importFn: () => import('o365-propertygrid/ui.index.ts').then((m) => m.OCollapse)
});

const isLoading = computed(() => dsRegisterAssignments.state.isLoading)

const props = defineProps<{
    definitionProc?: string,
    countersDefinitionProc?: string,
    definitionProcContext?: string,
    countersDefinitionProcContext?: string,
    responsible?: boolean,
    restrictToContext?: boolean,
}>();

const dateNow = new Date();

const collapsedStateMap = ref<Record<number, boolean>>({});

const localKey = 'scope-registers-assignments-collapse';
function readCollapsedState() {
    try {
        collapsedStateMap.value = {};
        const json = JSON.parse(localStorageHelper.getItem(localKey) || '{}');
        for (const key in json) {
            collapsedStateMap.value[+key] = json[key];
        }

    } catch (ex) {
        logger.error(ex);
    }
}
function storeCollapsedState() {
    try {
        localStorageHelper.setItem(localKey, JSON.stringify(collapsedStateMap.value));
    } catch (ex) {
        logger.error(ex);
    }
}
readCollapsedState();

function toggleCollapse(pRegsiterId: number) {
    const state = collapsedState(pRegsiterId);
    state.isCollapsed = !state.isCollapsed
}

function collapsedState(pRegisterId: number) {
    const state = {
        get isCollapsed() {
            return collapsedStateMap.value[pRegisterId];
        },
        set isCollapsed(value) {
            readCollapsedState();
            collapsedStateMap.value[pRegisterId] = value;
            storeCollapsedState();
        }
    };
    return state;
}

function restrictToContext(pContext: number) {
    if(pContext){
        dsRegisterAssignments.recordSource.definitionProc = props.definitionProcContext;
        dsRegisterAssignmentsCounters.recordSource.definitionProc = props.countersDefinitionProcContext;
    }else{
        dsRegisterAssignments.recordSource.definitionProc = props.definitionProc;
        dsRegisterAssignmentsCounters.recordSource.definitionProc = props.countersDefinitionProc;
    }
    dsRegisterAssignments.load();   
    dsRegisterAssignmentsCounters.load();   
}

defineExpose({ restrictToContext, isLoading: isLoading })

const dsProcesses = getOrCreateDataObject({
    "id": `dsProcesses-${props.registerId}`,
    "isStaticId": true,
    "viewName": "aviw_Scope_ProcessesForLookup",
    "distinctRows": true,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "EnforceCatAorB",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "HideProcessFromNewWF",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "IsChange",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "RequiresActivity",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },{
            "name": "CreateNewApp_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },{
            "name": "Register_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false,
    "useGroupedRequests": false
});

const dsRegisterAssignments = getOrCreateDataObject({
    "id": `dsRegisterAssignments-${props.definitionProc}`,
    "isStaticId": true,
    "viewName": "aviw_Scope_MyItems",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": 4,
            "sortDirection": "Desc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Filter",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "MobileFilter",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
         {
            "name": "HomeSortOrder",
            "sortOrder": 1,
            "sortDirection": "ascnullslast",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Register_ID",
            "sortOrder": 2,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Register",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Created",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Title",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "DetailApp_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "App_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "MobileDetailApp_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "HomeDetailApp_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "PlannedStart",
            "sortOrder": 3,
            "sortDirection": "Asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Closed",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "IsMeeting",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "DueDate",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false,
    "definitionProc": props.definitionProc,
    "useGroupedRequests": false
});

dsRegisterAssignments.recordSource.definitionProcParameters = { IsMobile: isMobile.value };


const dsRegisterAssignmentsCounters = getOrCreateDataObject({
    "id": `dsRegisterAssignmentsCounters-${props.definitionProc}`,
    "isStaticId": true,
    "viewName": "aviw_Scope_MyItemsCounters",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "enableGroupedRequests": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
         {
            "name": "Items",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
        
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false,
    "definitionProc": props.countersDefinitionProc,
    "useGroupedRequests": false
});


context.on('Change', (ctx) => {
    restrictToContext(props.restrictToContext);
});

watch(() => props.restrictToContext, () => restrictToContext(props.restrictToContext));

const getRegisters = computed(() => {
    console.log(dsRegisterAssignments)
    return dsRegisterAssignments.data.map( item => {
        return {Register_ID : item.Register_ID, Register: item.Register, Filter: item.Filter, MobileFilter: item.MobileFilter, App_ID:item.App_ID, NumOfItems:getNumOfItems(item.Register_ID)} 
            }).reduce((accumulator, current) => {

                const x = accumulator.find(item => item.Register_ID === current.Register_ID);
                if (!x) {
                    return accumulator.concat([current]);
                } else {
                    return accumulator;
                }
            }, [])
});


function getNumOfItems(pRegister_ID){
    if(dsRegisterAssignmentsCounters.data.filter(item => item.ID == pRegister_ID).length){
        return dsRegisterAssignmentsCounters.data.filter(item => item.ID == pRegister_ID)[0].Items;
    }else{
        return 0;
    }
}

function getItems(pRegister_ID){
    return dsRegisterAssignments.data.filter(item => item.Register_ID == pRegister_ID);
}

function newScopeItem(pRegisterId) {
    dsProcesses.recordSource.whereClause = "HideProcessFromNewWF = 0 AND AccessOrgUnit_ID = " + context.id + "AND Register_ID = " + pRegisterId;
    dsProcesses.load();
}

const getScopeItemDetailLink = (row) => {
    if(isMobile.value){
        if (row.MobileDetailApp_ID !== null) {
            return `/${row.MobileDetailApp_ID}?ID=${row.ID}`;
        }
    }
    if(row.HomeDetailApp_ID !== null){
        return `/${row.HomeDetailApp_ID}?ID=${row.ID}`;
    }
    return `/${row.DetailApp_ID}?ID=${row.ID}`;
}

function getScopeItemDate(row) {
    if (!row.PlannedStart) {
        return;
    }
    if (row.IsMeeting) {
        return utils.formatDate(row.PlannedStart, "dd.MM.yyyy HH:mm");
    } else {
        return utils.formatDate(row.PlannedStart, "Short Date");
    }
}

const parseDate = (date) => {
    return new Date(date);
};
</script>

<style scoped>
.register-chevron {
    display: inline-block;
    transition: all 200ms ease-in-out;
    transform: rotate(0deg);
}

.register-chevron.expanded {
    transform: rotate(90deg);
}
</style>